import React, { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { useAtom } from 'jotai';
import { localeAtom } from '../../store/locale';

export const LocaleOptions = ({ languages }) => {
  const [open, setOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useAtom(localeAtom);
  const selectedLocale = languages.find((lang) => lang.key === selectedLang);
  const options = open && (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <ul
        className="overflow-y-auto py-1 h-auto bg-white top-12 left-0 absolute text-aegean rounded shadow"
        aria-labelledby="dropdownLangButton"
      >
        {languages &&
          languages?.map((lang, index) => {
            return (
              <li key={lang + index}>
                <button
                  className="hover:bg-aegean/25 transition-colors flex w-full py-2 px-4"
                  onClick={() => {
                    setSelectedLang(lang.key);
                    setOpen(false);
                  }}
                >
                  {lang.component}
                  {lang.label}
                </button>
              </li>
            );
          })}
      </ul>
    </ClickAwayListener>
  );
  return (
    <div
      id="languages"
      className="z-10 h-full bg-white rounded shadow dark:bg-gray-700 block relative m-0 translate-x-3 translate-y-1"
    >
      <button
        className="text-white bg-aegean hover:bg-aegean/95 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
        id="dropdownLangButton"
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="whitespace-nowrap flex flex-nowrap">
          {selectedLocale.component} {selectedLocale.label}
        </div>
        <svg
          className="ml-2 w-4 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      {options}
    </div>
  );
};
