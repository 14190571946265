import React from 'react';
import { BritishFlag, GreekFlag } from 'components/Icons';

export const TRANSLATIONS = {
  el: {
    path: '/el/',
    key: 'el',
    label: 'Ελληνικά',
    component: <GreekFlag cssStyle={{ width: 25, height: 25, marginRight: 10 }} />,
  },
  'en-US': {
    path: '/en/',
    key: 'en-US',
    label: 'English',
    component: <BritishFlag cssStyle={{ width: 25, height: 25, marginRight: 10 }} />,
  },
};
