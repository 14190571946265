export const COOKIE_POLICY = {
  el: {
    TITLE_TEXT: 'ΠΟΛΙΤΙΚΗ COOKIES',
    MAIN_TEXT: `Χρησιμοποιούμε τα cookies για να βελτιστοποιούμε την εμπειρία σου στη σελίδα μας,
            παρέχοντάς σου εξατομικευμένο περιεχόμενο. Πατώντας Αποδοχή Cookies, επιτρέπεις τη
            χρήση τους και αποδέχεσαι την `,
    ACCEPT_BTN_TEXT: 'Έγινε',
  },
  'en-US': {
    TITLE_TEXT: 'COOKIES POLICY',
    MAIN_TEXT: `We use cookies to optimize your experience on our page,
            providing you with personalized content. By clicking Accept Cookies, you allow
            use them and you accept the `,
    ACCEPT_BTN_TEXT: 'Ok',
  },
};
