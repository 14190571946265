export const DEFAULT_PAGE_TITLE = 'Καλέστε τώρα για ταξί στην Αλεξανδρούπολη';
export const DEFAULT_PAGE_DESCRIPTION =
  'Βρείτε άμεσα ταξι Αλεξανδρουπολη. Διαδρομές από και προς αεροδρόμια και τελωνεία, ξεναγήσεις με ταξί Kyriakos taxi alexandroupoli. Τηλέφωνο 6944379553';
export const DEFAULT_PAGE_KEYWORDS =
  'ταξι αλεξανδρουπολη, Κυριάκος Παπαθεολόγου, 6944379553, ταξι αεροδρομιο αλεξανδρουπολη, ξεναγήσεις με ταξί, kyriakos papatheologou, taxi alexandroupoli, taxi airport';

export const DATA_SCHEMA = {
  '@context': 'https://schema.org',
  '@type': 'ProfessionalService',
  name: 'Κυριάκος Παπαθεολόγου',
  image: 'https://i.ibb.co/17YD0Mz/facebook-ad-taxi.png',
  '@id': 'https://www.taxikyriakos.gr',
  url: 'https://www.taxikyriakos.gr',
  telephone: '6944379553',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '',
    addressLocality: 'Alexandroupoli',
    postalCode: '68100',
    addressCountry: 'GR',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 25.873962,
    longitude: 25.873962,
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    opens: '00:00',
    closes: '23:59',
  },
};
