import React from 'react';
import logo from '../../styles/icons/logo.png';
import { Link } from 'gatsby';
import { PhoneInfo } from 'components/Common';
import { LocaleOptions } from '../Locale/LocaleOptions';
import { TRANSLATIONS } from '../../constants/translationPaths';

import { Phone as PhoneIcon } from '@material-ui/icons';
const Hero = ({ data: { driverNumber, driverName, driverSurname, pageTitle }, imgSrc }) => {
  return (
    <header
      itemScope
      itemType="https://schema.org/TaxiService"
      className="bg-white dark:bg-gray-800 "
    >
      <nav className="border-t-4 border-taxi-yellow">
        <div className="container flex items-center justify-between px-6 mx-auto">
          <div>
            <Link to={'/'}>
              <img
                className="text-2xl font-bold text-aegean  lg:text-3xl hover:text-aegean/95 "
                itemProp={'logo'}
                src={logo}
                alt={'kyriakos papatheologou site logo'}
                width={'200'}
                height={'auto'}
                style={{ marginBottom: 5 }}
              />
            </Link>
          </div>
          <div
            className="flex my-1 text-sm font-medium text-aegean/95 hover:text-aegean/75 0 lg:mx-4 lg:my-0"
            href="#"
          >
            <LocaleOptions languages={Object.values(TRANSLATIONS)} />

            <PhoneInfo
              paddingLeft={16}
              marginLeft={40}
              driverNumber={driverNumber}
              variant={'body1'}
            />
          </div>
        </div>
      </nav>

      <div className="container px-6 pb-16 mx-auto">
        <div className="items-center lg:flex">
          <div className="w-full lg:w-1/2">
            <div className="lg:max-w-lg">
              <h1
                itemProp="slogan"
                className="text-3xl font-semibold text-aegean dark:text-white lg:text-6xl"
              >
                {pageTitle}
              </h1>

              <h2 itemProp="brand" className="mt-4 text-5xl  text-aegean/75">
                {`${driverName} ${driverSurname}`}
              </h2>
              <p className="m-0 p-0 flex flex-nowrap items-center phone-on-mobile">
                <PhoneIcon className="!fill-taxi-yellow text-yellow-taxi m-1" itemProp={'image'} />
                <span className="text-yellow-taxi text-aegean text-xl">{driverNumber}</span>
              </p>
              <div className="flex flex-col mt-8 space-y-3 lg:space-y-0 lg:flex-row"></div>
            </div>
          </div>

          <div className="flex items-center justify-end	 w-full mt-6 lg:mt-0 lg:w-1/2">
            <img
              itemProp="image"
              className="w-full h-full"
              src={imgSrc}
              alt="Alexandroupolis taxi service"
            />
          </div>
        </div>
      </div>
      <div className="z-10 relative">
        <div className="icon-scroll" />
      </div>
    </header>
  );
};

export default Hero;
