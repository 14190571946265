import React from 'react';

const RoadIcon = ({ cssClass = '' }) => {
  return (
    <div className={cssClass} itemProp={'logo'}>
      <svg data-name="Layer 1" width="200" height="150" viewBox="0 0 850 520.84494">
        <path
          d="M717.33682,462.93546a14.65213,14.65213,0,0,0,11.0391-26.92491L697.2754,400.727,670.97048,406.41l36.99734,36.23808a14.573,14.573,0,0,0,9.36894,20.28732Z"
          transform="translate(-175 -189.57753)"
          fill="#a0616a"
        />
        <polygon
          points="440.016 147.04 517.531 244.34 541.159 233.492 450.049 91.647 440.016 147.04"
          fill="#3f3d56"
        />
        <path
          id="bbb65a06-4388-4569-87f8-fdcf21a5aae9-557"
          data-name="a9adb9ea-63bd-4fab-ab8c-5a8362c24c0e"
          d="M967.41261,672.89861c4.858-9.424,2.219-21.314-3.828-30.024s-14.976-14.916-23.709-20.929l-12.143-8.362,5.8,29.112c1.958,9.843,4.371,20.472,12.058,26.924a22.72784,22.72784,0,0,0,21.947,3.866"
          transform="translate(-175 -189.57753)"
          fill="#e6e6e6"
        />
        <path
          id="b60b4bdd-1468-4587-b387-9dd74123fc14-558"
          data-name="ed5b34f6-0534-4519-a094-dcdf83ab50dc"
          d="M966.49561,708.73064a.878.878,0,0,1-1.074.61.9.9,0,0,1-.61-1.074c.677-4.93,1.348-9.863,1.665-14.83.528-8.213.04-16.642-3-24.374a35.30026,35.30026,0,0,0-13.963-16.847c-.948-.598-.072-2.107.881-1.507a37.132,37.132,0,0,1,9.527,8.633c4.992,6.436,7.443,14.285,8.209,22.321.862,9.049-.402,18.116-1.631,27.069Z"
          transform="translate(-175 -189.57753)"
          fill="#f2f2f2"
        />
        <path
          id="bb361191-ab63-4eee-8f74-e0c90ef218a4-559"
          data-name="e0867f2b-861c-49f4-a2d1-8ca31a29fe8f"
          d="M842.90262,653.43962c38.258,23.543,25.505,54.934,25.505,54.934Z"
          transform="translate(-175 -189.57753)"
          fill="#e6e6e6"
        />
        <path
          id="a8c2400c-de96-4d85-b082-a15f0836198d-560"
          data-name="b461d634-05fb-4647-ba4f-16823402cab1"
          d="M918.09158,685.62059c-30.11-.192-34,22.182-34,22.182Z"
          transform="translate(-175 -189.57753)"
          fill="#e6e6e6"
        />
        <circle cx="257.33293" cy="512.14977" r="8.50192" fill="#3f3d56" />
        <path
          d="M438.51615,530.53009S458.61162,469.471,460.15738,465.22s17.77664,2.70511,22.0276,3.86446,3.09164,5.41031,3.09164,5.41031l-21.64116,62.60485,6.56959,3.09164,20.09541-62.99136c11.59355-11.97993,3.09164-12.75284,3.09164-12.75284s-24.73274-8.50191-34.0076-9.27482-5.79678,10.43414-5.79678,10.43414l-22.4141,59.51331Z"
          transform="translate(-175 -189.57753)"
          fill="#3f3d56"
        />
        <path
          d="M486.82225,545.215l-.00386-.00387a24.09955,24.09955,0,0,0-11.58963-8.88449q-.24933-.09278-.57966-.20868c-10.64666-3.72155-84.82574-23.75118-84.82574-23.75118s-14.685-5.41021-21.25466,9.27486-45.60109,136.41683-45.60109,136.41683,76.33922,40.82459,98.5949,45.26877a13.44168,13.44168,0,0,0,4.2007.33232,59.23347,59.23347,0,0,0,11.11821-2.65489,46.53237,46.53237,0,0,0,11.296-5.46059l43.28239-129.46074S497.643,551.78476,486.82225,545.215Z"
          transform="translate(-175 -189.57753)"
          fill="#f2c75c"
        />
        <path
          d="M486.82231,545.21516l-.00386-.00386a24.09958,24.09958,0,0,0-11.58969-8.88449q-.24933-.09288-.57966-.20868c3.50134,6.13676,4.83068,15.66669,4.83068,15.66669s-42.50951,126.36916-46.374,138.34909c-2.94474,9.13176-8.88442,12.23494-11.54324,13.19337a13.44176,13.44176,0,0,0,4.20071.33232,59.23355,59.23355,0,0,0,11.1182-2.65489,24.73761,24.73761,0,0,0,2.794-3.91477S482.95792,567.6292,486.82238,561.446C490.6869,555.26271,486.82231,545.21516,486.82231,545.21516Z"
          transform="translate(-175 -189.57753)"
          fill="#3f3d56"
        />
        <circle cx="231.24753" cy="512.34299" r="8.50192" fill="#3f3d56" />
        <circle cx="148.74048" cy="476.59639" r="8.50192" fill="#3f3d56" />
        <path
          d="M473.59937,470.39528a14.65214,14.65214,0,0,1-11.99122-26.51459l29.82392-36.36905,26.49066,4.74243-35.683,37.533a14.57308,14.57308,0,0,1-8.64032,20.60819Z"
          transform="translate(-175 -189.57753)"
          fill="#a0616a"
        />
        <polygon
          points="501.866 440.846 520.34 440.556 518.118 503.522 501.869 503.522 501.866 440.846"
          fill="#a0616a"
        />
        <path
          d="M672.724,687.79454l32.00113-.00128h.00131a20.39475,20.39475,0,0,1,20.39362,20.39331v.66272l-52.39508.00195Z"
          transform="translate(-175 -189.57753)"
          fill="#2f2e41"
        />
        <polygon
          points="379.549 424.146 399.73 437.102 359.362 485.667 345.688 476.888 379.549 424.146"
          fill="#a0616a"
        />
        <path
          d="M520.06647,659.7617l26.92907,17.2887.0011.00067a20.39472,20.39472,0,0,1,6.14264,28.179l-.358.55768-44.09052-28.30671Z"
          transform="translate(-175 -189.57753)"
          fill="#2f2e41"
        />
        <path
          d="M547.35937,421.45269s44.83957-148.9274,59.24978-150.93993,37.62808,38.21305,37.62808,38.21305c30.103,38.40821,61.144,162.82087,61.144,162.82087s-51.74659,3.23809-89.57339,23.36344-82.46764-1.0188-82.46764-1.0188Z"
          transform="translate(-175 -189.57753)"
          fill="#3f3d56"
        />
        <path
          d="M547.35937,421.45269s44.83957-148.9274,59.24978-150.93993S638.34021,309.134,638.34021,309.134c15,62,67.04105,162.41266,67.04105,162.41266s-51.74659,3.23809-89.57339,23.36344-82.46764-1.0188-82.46764-1.0188Z"
          transform="translate(-175 -189.57753)"
          opacity="0.1"
        />
        <polygon
          points="409.147 187.317 409.147 262.152 400.34 370.556 356.34 452.556 377.34 467.556 437.34 389.556 461.071 312.107 498.34 483.556 526.34 481.556 506.894 243.845 469.993 176.935 409.147 187.317"
          fill="#2f2e41"
        />
        <path
          d="M629.35645,304.51228c14,14,1,29,1,29l16,36-63,17L561.34021,288.134l15-16c30-1,30.01624-1.62174,30.01624-1.62174C611.61267,275.65611,629.35645,304.51228,629.35645,304.51228Z"
          transform="translate(-175 -189.57753)"
          fill="#ccc"
        />
        <path
          d="M558.455,278.19847h0a26.87924,26.87924,0,0,0-7.77734,34.32911c7.21777,13.77783,14.98144,35.12011,10.978,58.5498-7.24414,42.40234-30.46631,74.61768-43.40186,84.96387-4.50342,3.60156-4.66064,8.84668-3.99951,12.6123,1.84668,10.52051,12.04736,22.459,19.10254,24.85254a4.55981,4.55981,0,0,0,1.47314.23145c11.46192.0039,39.65625-36.501,72.05518-107.81055,20.17773-44.41065,5.5332-80.01953-10.30469-102.07178a26.8839,26.8839,0,0,0-37.77734-5.91308Z"
          transform="translate(-175 -189.57753)"
          fill="#3f3d56"
        />
        <polygon
          points="413.34 131.556 322.356 250.935 298.356 240.935 384.356 95.935 413.34 131.556"
          fill="#3f3d56"
        />
        <path
          d="M615.328,257.55132H552.749a4.86078,4.86078,0,0,1-4.85528-4.85526V225.72234h0a36.14481,36.14481,0,1,1,72.28961,0v26.97372A4.86078,4.86078,0,0,1,615.328,257.55132Z"
          transform="translate(-175 -189.57753)"
          fill="#2f2e41"
        />
        <circle
          cx="591.46588"
          cy="230.42328"
          r="26.50011"
          transform="translate(-69.42256 449.3053) rotate(-61.3368)"
          fill="#a0616a"
        />
        <path
          d="M628.73431,228.41969H590.46875l-.39252-5.49379-1.96191,5.49379h-5.89209l-.77759-10.88853-3.88855,10.88853H566.15521v-.53947a28.62427,28.62427,0,0,1,28.59192-28.59215h5.395a28.62448,28.62448,0,0,1,28.59216,28.59215Z"
          transform="translate(-175 -189.57753)"
          fill="#2f2e41"
        />
        <path
          d="M590.15558,262.58653a4.95761,4.95761,0,0,1-.85925-.07586l-28.01978-4.94379v-46.3084h30.84436l-.76361.89034c-10.62463,12.39105-2.62024,32.48332,3.09668,43.35712a4.78338,4.78338,0,0,1-.38013,5.07864A4.83582,4.83582,0,0,1,590.15558,262.58653Z"
          transform="translate(-175 -189.57753)"
          fill="#2f2e41"
        />
        <path
          d="M175,709.42247a1,1,0,0,1,1-1h848a1,1,0,0,1,0,2H176A1,1,0,0,1,175,709.42247Z"
          transform="translate(-175 -189.57753)"
          fill="#cbcbcb"
        />
      </svg>
    </div>
  );
};

export default RoadIcon;
