import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const theme = createTheme({
  palette: {
    primary: {
      main: grey[200],
      dark: '#000028',
    },
    secondary: {
      main: '#f2c75c',
      light: '#f2c75c',
    },
  },
});

export default theme;
