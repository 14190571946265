import React from 'react';

const LinkWrapper = ({ url, children, cssClass, alt }) => {
  return url ? (
    <a
      alt={alt}
      href={url}
      className={cssClass}
      onClick={() => {
        window.gtag_report_conversion?.(url);
      }}
    >
      {children}
    </a>
  ) : (
    <div className={cssClass}>{children}</div>
  );
};

export default LinkWrapper;
