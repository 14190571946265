import { makeStyles } from '@material-ui/core/styles';

const phoneWrapperMobile = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  background: '#eeeeee',
  padding: 10,
  borderRadius: '100%',
  zIndex: 100,
};

const phoneWrapperDesktop = {};
export const usePhoneStyles = ({
  marginLeft,
  paddingLeft,
  iconColor,
  textColor = '',
  size = '1.8875rem',
  disableMarginTop = false,
  isDesktop = true,
}) =>
  makeStyles((theme) => ({
    phone: {
      paddingLeft,
      color: textColor || theme.palette.secondary.light,
      fontSize: isDesktop ? size : '2rem',
      display: isDesktop ? 'block' : 'none',
    },
    phoneContainer: {
      width: 'auto',
      margin: 'auto',
      marginTop: disableMarginTop ? 0 : 'auto',
      marginLeft,
      display: 'flex',
      justifyContent: 'flex-start',
      ...(isDesktop ? phoneWrapperDesktop : phoneWrapperMobile),
    },
    phoneIcon: {
      fontSize: isDesktop ? size : '2.8875rem',
      color: iconColor || theme.palette.secondary.light,
      margin: 'auto',
    },
    outboundLink: {
      textDecoration: 'none',
    },
  }));

export const dialogPopUpStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#1b365d',
    color: '#fff',
    fontFamily: '"Roboto"',
    padding: 30,
    textAlign: 'center',
  },
  container: {
    background: `${theme.palette.primary.dark} no-repeat right -4px`,
    borderRadius: 4,
    boxShadow: '-10px 10px 14px 0 rgba(0,0,0,.61)',
    position: 'fixed',
    bottom: 30,
    right: 30,
    maxWidth: 394,
    zIndex: 1000,
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 50,
    color: '#fff',
    fontSize: 30,
    cursor: 'pointer',
  },
  title: {
    fontSize: 18,
    lineHeight: 1.22,
    color: '#fff',
    marginBottom: 16,
  },
  content: {
    margin: '10px 0',
  },
  link: {
    color: 'white',
    '&:visited': {
      color: 'white',
    },
  },
}));
