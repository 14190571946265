import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Fade } from '@material-ui/core';
import { confirmAnalytics, hasConfirmedAnalytics } from '../../constants/analytics';
import { useLocaleContext } from '../../Providers/LocaleProvider';
import { COOKIE_POLICY } from '../../constants/cookie-policy';
import { dialogPopUpStyles } from 'components/Common/styles';
import { Link } from 'gatsby';
import { getMenuText } from '../../constants/menu';

const isBrowser = typeof window !== 'undefined';

export default function DialogPopUp() {
  const { locale } = useLocaleContext();
  const [timer, setTimer] = useState(10);
  const [percentage, setPercentage] = useState(100);
  const [open, setOpen] = useState(() => {
    return isBrowser && localStorage.getItem('consentGranted') !== 'true';
  });

  useEffect(() => {
    let timeoutId;
    if (timer === 0) {
      setPercentage(0);

      timeoutId = setTimeout(() => {
        setOpen(false);
        confirmAnalytics('false');
      }, 1000);

      return;
    }

    setPercentage((timer / 10) * 100);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [timer]);

  const classes = dialogPopUpStyles();
  const { MAIN_TEXT, ACCEPT_BTN_TEXT, TITLE_TEXT } = COOKIE_POLICY[locale];

  const handleClick = () => {
    setOpen(false);
    confirmAnalytics('true');
  };

  const id = open ? 'simple-popper' : undefined;

  return (
    <Fade in={open}>
      <aside id={id} className={classes.container}>
        <CloseIcon className={classes.close} onClick={handleClick} />
        <div className={classes.paper}>
          <div className={classes.title}>{TITLE_TEXT}</div>
          <div className={classes.content}>
            {MAIN_TEXT}
            <Link to={'/privacy-policy'} className={classes.link}>
              {getMenuText(locale).privacyPolicy}
            </Link>
          </div>

          <Button variant="contained" color="default" onClick={handleClick}>
            {ACCEPT_BTN_TEXT}
          </Button>
          <div
            style={{ background: '#000028' }}
            className="bg-taxi-yellow rounded-xl shadow-sm overflow-hidden p-0.5 mt-4"
          >
            <div className="relative h-0.5 flex items-center justify-center">
              <div
                style={{
                  width: percentage !== 0 ? `${percentage}%` : percentage,
                }}
                className={'absolute top-0 bottom-0 left-0 rounded-lg bg-taxi-yellow'}
              ></div>
            </div>
          </div>
        </div>
      </aside>
    </Fade>
  );
}
