import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Phone as PhoneIcon } from '@material-ui/icons';
import { usePhoneStyles } from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinkWrapper from './LinkWrapper';
import { desktopLimit } from '../../constants';

const PhoneInfo = ({
  marginLeft,
  iconColor = '',
  driverNumber,
  textColor = '#000028',
  size = '1.8875rem',
  variant = 'h3',
  disableMarginTop = false,
}) => {
  const isDesktop = useMediaQuery(desktopLimit);
  const classes = usePhoneStyles({
    marginLeft,
    iconColor,
    textColor,
    size,
    disableMarginTop,
    isDesktop,
  })();
  const url = !isDesktop && `tel:+30${driverNumber}`;
  const marginTop = disableMarginTop ? 'mt-0' : 'mt-auto';

  return (
    <Grid className={`w-auto m-auto ${marginTop} ml-10 flex justify-start phone-info`}>
      <LinkWrapper alt="driver's phone number" url={url} cssClass={'flex no-underline'}>
        <PhoneIcon itemProp={'image'} className={classes.phoneIcon} />
        <Typography
          itemProp={'telephone'}
          color={'primary'}
          variant={variant}
          className={classes.phone}
        >
          {driverNumber}
        </Typography>
      </LinkWrapper>
    </Grid>
  );
};

export default PhoneInfo;
