import { useAtomValue, useHydrateAtoms } from 'jotai/utils';

import { localeAtom } from '../store/locale';

export const useLocaleContext = () => {
  useHydrateAtoms([[localeAtom, 'el']]);

  const selectedLocale = useAtomValue(localeAtom);

  return { locale: selectedLocale };
};
