import './src/styles/global.css';
import CustomLayout from './wrapPageElement';

window.gtag_report_conversion = function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof url != 'undefined') {
      window.location = url;
    }
  };

  gtag('event', 'conversion', {
    send_to: 'AW-642836220/d-k5CKLF1-IYEPzNw7IC',
    event_callback: callback,
  });
};

export const wrapPageElement = CustomLayout;

export const onServiceWorkerUpdateReady = () => {
  return window.location.reload();
};
