import Cookies from 'universal-cookie';
const cookies = new Cookies();

const denied = {
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  wait_for_update: 500,
};

const granted = {
  ad_user_data: 'granted',
  ad_personalization: 'granted',
  ad_storage: 'granted',
  analytics_storage: 'granted',
};

const GA_COOKIE = 'gatsby-gdpr-google-analytics';
const ANALYTICS_ACKNOWLEDGED = 'analytics-acknowledged';

export const setUserAnalytics = (status = false) => {
  cookies.set(GA_COOKIE, status);
};

export const getUserAnalytics = () => cookies.get(GA_COOKIE);

export const confirmAnalytics = (status = 'false') => {

  const isGranted = status === 'true';

  if (!window.dataLayer) return;

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('consent', isGranted ? 'update' : 'default', isGranted ? granted : denied);
  localStorage.setItem('consentGranted', status);

  cookies.set(ANALYTICS_ACKNOWLEDGED, status);

};


export const removeUserAnalytics = () => cookies.remove(GA_COOKIE);
