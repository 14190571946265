import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../utils/theme';
import { graphql, useStaticQuery } from 'gatsby';
import '../../styles/global.css';
import MainContentView from '../Views/MainContentView';
import Footer from '../Footer/Footer';
import { useLocation } from '@reach/router';
import DialogPopUp from '../Common/DialogPopUp';
const query = graphql`
  {
    allContentfulWelcomePage {
      edges {
        node {
          driverName
          driverNumber
          driverSurname
          pageTitle
          node_locale
          id
          sectionTitleInfo
          sectionTitleDetails
        }
      }
    }
    allContentfulAsset {
      nodes {
        description
        node_locale
        title
        file {
          url
        }
      }
    }
  }
`;

const useMaterialRehydrationAfterSSR = () => {
  const [isClient, setClient] = useState(false);

  const key = isClient ? `client` : `server`;

  useEffect(() => {
    setClient(true);
  }, []);

  return { rehydrationKey: key };
};

const Layout = ({ children }) => {
  // rehydrates material ui after SSR - issue fix
  const { rehydrationKey } = useMaterialRehydrationAfterSSR();

  const { pathname } = useLocation();
  const {
    allContentfulWelcomePage: { edges: welcomePageEntries },
    allContentfulAsset: { nodes: contentfulAssetEntries },
  } = useStaticQuery(query);
  const mainViewProps = {
    welcomePageEntries,
    pathname,
  };

  return (
    <ThemeProvider theme={theme} key={rehydrationKey}>
      <DialogPopUp />
      <MainContentView
        welcomePageEntries={welcomePageEntries}
        {...mainViewProps}
        contentfulAssetEntries={contentfulAssetEntries}
      />
      {children}
      <Footer welcomePageEntries={welcomePageEntries} />
    </ThemeProvider>
  );
};

export default Layout;
