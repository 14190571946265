import React from 'react';

const GreekFlag = ({ cssStyle }) => {
  return (
    <div style={cssStyle ? cssStyle : {}}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
      >
        <circle fill="#F0F0F0" cx="256" cy="256" r="256" />
        <g>
          <path
            fill="#338AF3"
            d="M256,189.217h247.181c-6.419-23.814-16.175-46.255-28.755-66.783H256V189.217z"
          />
          <path
            fill="#338AF3"
            d="M96.643,456.348h318.713c23.363-18.608,43.399-41.21,59.069-66.783H37.574
		C53.245,415.137,73.281,437.74,96.643,456.348z"
          />
          <path
            fill="#338AF3"
            d="M89.043,61.939c-20.126,17.331-37.525,37.74-51.469,60.496h51.469V61.939z"
          />
          <path
            fill="#338AF3"
            d="M256,256c0-26.076,0-47.899,0-66.783H155.826V256H89.043v-66.783H8.819
		C3.08,210.511,0,232.893,0,256s3.08,45.489,8.819,66.783h494.363C508.92,301.489,512,279.107,512,256H256z"
          />
          <path
            fill="#338AF3"
            d="M256,0c-35.549,0-69.404,7.253-100.174,20.348v102.087H256c0-23.312,0-43.139,0-66.783h159.357
		C371.626,20.824,316.249,0,256,0z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default GreekFlag;
