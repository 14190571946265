import React from 'react';
import { useLocaleContext } from '../../Providers/LocaleProvider';
import { getDriverDetailsByLocale, getImagesByLocale } from '../../utils/contentful';
import Hero from 'components/Hero/Hero';

const MainContentView = ({ welcomePageEntries, contentfulAssetEntries }) => {
  const { locale } = useLocaleContext();
  const { driverNumber, driverName, driverSurname, pageTitle } = getDriverDetailsByLocale(
    welcomePageEntries,
    locale,
  );
  const cmsImages = getImagesByLocale(contentfulAssetEntries);
  const imageSrc = cmsImages.find((file) => file.title === 'taxi-bg-webp')?.file?.url || '#';

  return <Hero data={{ driverNumber, driverName, driverSurname, pageTitle }} imgSrc={imageSrc} />;
};

export default MainContentView;
