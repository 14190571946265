import { omit } from 'lodash';

export const getDriverDetailsByLocale = (edges, locale = 'el') => {
  const driverInfo =
    edges &&
    edges.find(({ node }) => {
      return node?.node_locale === locale;
    });

  return {
    driverName: driverInfo?.node?.driverName,
    driverNumber: driverInfo?.node?.driverNumber,
    driverSurname: driverInfo?.node?.driverSurname,
    pageTitle: driverInfo?.node?.pageTitle,
    sectionTitleInfo: driverInfo?.node?.sectionTitleInfo,
    sectionTitleDetails: driverInfo?.node?.sectionTitleDetails,
  };
};

export const getImagesByLocale = (imageEntries, locale = 'el') => {
  return imageEntries.filter((imageEntry) => imageEntry.node_locale === locale);
};

export const getDescriptionByLocale = (descriptionEntries, locale = 'el') => {
  const localedDescription = descriptionEntries.find((desc) => desc.node_locale === locale);

  return {
    mainDescription: localedDescription?.mainDescription?.raw,
  };
};

export const getLegalByLocale = (legalEntries, locale = 'el') => {
  const localed = legalEntries.find((desc) => desc.node_locale === locale);

  return {
    legalContent: localed?.legalContent?.raw,
    legalTitle: localed.legalTitle,
  };
};

export const getDetailsByLocale = (detailsEntries, locale = 'el') => {
  const localedDetails = detailsEntries.find((desc) => desc.node_locale === locale);
  return omit(localedDetails, ['node_locale']);
};

export const getInfoSectionLocale = (nodes, locale = 'el') => {
  return (
    nodes &&
    nodes
      .filter((node) => {
        return node.node_locale === locale;
      })
      .sort((a, b) => {
        return a.order - b.order;
      })
  );
};

export const getSeoData = (pageId, seoDataList, locale = 'el') => {
  const localizedSeo = seoDataList.filter((seoData) => seoData.node_locale === locale);
  const {
    metaTitle: { metaTitle },
    metaDescription: { metaDescription },
    metaKeywords: { metaKeywords },
  } = localizedSeo.find((seoData) => seoData.pageId === pageId);

  return { title: metaTitle, description: metaDescription, keywords: metaKeywords };
};
