import React from 'react';
import { Link } from 'gatsby';
import { useLocaleContext } from '../../Providers/LocaleProvider';
import { getDriverDetailsByLocale } from '../../utils/contentful';
import { getFooterMenu } from '../../constants/footer';

const Footer = ({ welcomePageEntries }) => {
  const currentDate = new Date();
  const { locale } = useLocaleContext();
  const { driverName, driverSurname, driverNumber } = getDriverDetailsByLocale(
    welcomePageEntries,
    locale,
  );
  const { home, privacyPolicy } = getFooterMenu(locale);

  return (
    <footer className="flex justify-center px-4 text-aegean bg-white ">
      <div className="container py-6">
        <hr className="h-px mt-6 border-gray-300 border-none" />

        <div className="flex flex-col items-center justify-between mt-6 md:flex-row">
          <div>
            <span href="#" className="text-xl font-bold text-aegean  hover:text-aegean/95 ">
              &copy; Copyright {currentDate.getFullYear()},{' '}
              {`${driverName} ${driverSurname}, ${driverNumber}`}
            </span>
          </div>

          <div className="flex mt-4 md:m-0">
            <div className="flex mx-4">
              <Link
                to="/privacy-policy"
                className="px-4 text-sm font-medium text-aegean dark:text-gray-200 hover:text-aegean/95 dark:hover:text-gray-400 hover:underline"
              >
                {privacyPolicy}
              </Link>
              <Link
                to="/"
                className="px-4 text-sm font-medium text-aegean dark:text-gray-200 hover:text-aegean/95 dark:hover:text-gray-400 hover:underline"
              >
                {home}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{
  /* <footer style={footerWrapper}>
      <Typography variant={'body2'}>
        &copy; Copyright {currentDate.getFullYear()}, {`${driverName} ${driverSurname}`}
      </Typography>
      <Link style={footerLink} to={'/'}>
        {home}
      </Link>
      <Link to={'/privacy-policy'} style={linkStyle}>
        {privacyPolicy}
      </Link>
    </footer> */
}
